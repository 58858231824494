<template>
	<slot :items="items" :removeItem="removeItem" :addItem="addItem" :addItemAfter="addItemAfter" />
</template>

<script setup>
import { useFieldArray } from 'vee-validate';
import { inject, toRef } from 'vue';

const props = defineProps({
	name: {
		type: String,
		required: true,
	},
})
const { remove: removeItem, push: pushItem, insert: insertItem, fields: items } = useFieldArray(toRef(props, 'name'));
const getNewFieldArrayItem = inject('getNewFieldArrayItem');

const addItem = () => {
	pushItem(getNewFieldArrayItem(props.name));
};
const addItemAfter = i => {
	insertItem(i + 1, getNewFieldArrayItem(props.name));
};

defineExpose({
	items,
	removeItem,
	addItem,
	addItemAfter,
});
</script>
